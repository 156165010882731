// gère la fermeture de la modal d'affichage des prévisions

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("keydown", this.handleEscape);
    this.modalElement = document.getElementById("global-modal");
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleEscape);
  }

  close() {
    this.modalElement?.classList.remove("open");
  }

  handleEscape = (event) => {
    if (event.key === "Escape") {
      this.close();
    }
  };
}
