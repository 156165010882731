import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sessions"];
  static values = { url: String, page: Number , spotsWorkingToday: Array , spotsToDisplay: Array };

  initialize() {
    this.scrollLoad = false;
    this.hasLoadedAll = false;
    this.mapMoved = false;
    this.markers = L.layerGroup();
    // Initialisez le layerGroup pour les cercles
    this.circleLayerGroup = L.layerGroup();
    console.log(" controller initialized")
  }

  connect() {
    this.map = window.map;
    let previousPosition = this.map.getCenter(); // Obtenir la position initiale du centre de la carte

    this.map.on("dragend", () => {
      let currentPosition = this.map.getCenter(); // Obtenir la position du centre de la carte après le glissement

      // Calculer la distance entre l'ancienne et la nouvelle position
      let distance = previousPosition.distanceTo(currentPosition);

      if (distance >= 2500) {
        // Si la distance est supérieure ou égale à 2.5 km
        previousPosition = currentPosition; // Mettre à jour la position précédente
        this.mapMoved = true;
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
          this.pageValue = 0;
          this.scrollLoad = false;
          this.hasLoadedAll = false;
          document.getElementById("session").scrollTop = 0;
          this.markers.clearLayers(); // effacer les marqueurs existants
          this.updateSession();
        }, 2000);
      }
    });
    // declenche le chargement de la suite des sessions quand on arrive en bas de la page
    document.getElementById("session").addEventListener("scroll", (e) => {
      if (
        e.target.scrollHeight - e.target.scrollTop ===
        e.target.clientHeight
      ) {
        if (!this.hasLoadedAll && this.mapMoved) {
          this.scrollLoad = true;
          this.loadMore();
        }
      }
    });
    this.displayWorkingSpots();
    this.displaySpots();

    // ecoute l'evenement updateLocation déclenché par la bibliotheque leaflet geocoder
    document.addEventListener("updateLocation", this.updateLocation.bind(this));

    document.addEventListener(
      "searchLocationChange",
      this.updateLocationSearch.bind(this)
    );

    // Initialisez le contrôle de localisation
    this.locateControl = L.control
      .locate({ position: "bottomright" })
      .addTo(this.map);

    // Écoutez l'événement locationfound
    this.map.on("locationfound", this.handleLocationFound.bind(this));
  }


  disconnect() {
    console.log("disconnected");
    this.sessionsTarget.removeEventListener("scroll");
    document.removeEventListener("updateLocation");
    this.map.off("locationfound", this.handleLocationFound);
  }

  fetch_session(lat, lon, page) {
    this.beforeReflex();

    let latitude = lat;
    let longitude = lon;

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
      },
      body: JSON.stringify({
        latitude: latitude,
        longitude: longitude,
        page: page,
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        let obj = JSON.parse(data);
        if (!this.scrollLoad) {
          this.sessionsTarget.innerHTML = obj.html;
          this.spotsWorkingTodayValue = obj.spots_working_today;
          this.spotsToDisplayValue = obj.spotlist_to_display;
          // afficher les nouveaux spots sur la carte
          this.markers.clearLayers();
          this.displayWorkingSpots();
          this.displaySpots();
        } else {
          this.sessionsTarget.insertAdjacentHTML("beforeend", obj.html);
        }
        this.afterReflex();
      });
  }

  updateGeoCookie(center) {
    var cookie_val = center.lat + "|" + center.lng;
    document.cookie =
      "lat_lng=" + encodeURIComponent(cookie_val) + "; path=/; SameSite=None; Secure";
  }

  updateSessionStorage(center) {
    localStorage.setItem("last_latitude", center.lat);
    localStorage.setItem("last_longitude", center.lng);
  }

  updateSession() {
    let center = map.getCenter();
    this.updateGeoCookie(center);
    this.updateSessionStorage(center);
    this.fetch_session(center.lat, center.lng, this.pageValue);
  }

  loadMore() {
    this.pageValue += 1;
    this.updateSession();
    this.hasLoadedAll = true;
  }

  // ecoute l'evenement updateLocation déclenché par la bibliotheque leaflet geocoder
  updateLocation() {
    this.updateSession();
  }

   // ecoute l'evenement updateLocation déclenché par la bibliotheque leaflet geocoder depuis la search bar
  updateLocationSearch(e) {
    var center = e.detail;
    this.updateGeoCookie(center);
    this.updateSessionStorage(center);
    this.fetch_session(center.lat, center.lng, this.pageValue);
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }

  beforeReflex() {
    document.getElementById("spinner").style.display = "block";
  }

  afterReflex() {
    document.getElementById("spinner").style.display = "none";
  }


  displayWorkingSpots() {
    const iconUrl = document.querySelector('meta[name="marker-icon-url"]').getAttribute("content");
    const shadowUrl = document.querySelector('meta[name="marker-shadow-url"]').getAttribute("content");

    const greenIcon = L.icon({
      iconUrl: iconUrl,
      shadowUrl: shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });

    let convertedSpots = this.convertSpot(this.spotsWorkingTodayValue);

    convertedSpots.forEach(spot => {
      let layer = L.geoJSON(spot, {
        onEachFeature: this.onEachFeature,
        pointToLayer: function(feature, latlng) {
          return L.marker(latlng, {icon: greenIcon});
        }
      });
      this.markers.addLayer(layer);
    });
    this.markers.addTo(this.map);
  }


  // Pour chaque spot, créez un marqueur et ajoutez-le à la carte
  displaySpots() {
  this.circleLayerGroup.clearLayers();

  this.spotsToDisplayValue.forEach((spot) => {
    const { latitude, longitude, label, slug, sport } = spot;
    // Créez un cercle pour représenter le spot
    const circle = L.circle([latitude, longitude], {
      color: 'gray',      // Couleur du cercle
      fillColor: '#f03',  // Couleur de remplissage
      fillOpacity: 0.5,   // Opacité du remplissage
      // radius: 50     // Taille du cercle
      radius: this.calculateRadius(this.map.getZoom())     // Taille du cercle
    });

    // Ajoutez un popup qui s'ouvre sur un clic, si nécessaire
   var popupContent = `
        <a href="#" class="text-decoration-none" data-controller="spot-forecast" data-action="click->spot-forecast#open" data-spot-forecast-url-value="/spots/${slug}/details">
          <div class="d-flex flex-column align-items-center justify-content-center p-0" >
            <div class="mb-0 text-center">${label} // ${sport}</div>
            <button type="button" class="btn btn-outline-success btn-sm mt-2 px-3 rounded-pill">Forecast</button>
          </div>
        </a>`;
   circle.bindPopup(popupContent);


    // Ajoutez le cercle au layerGroup au lieu de la carte directement
    this.circleLayerGroup.addLayer(circle);
  });
  this.circleLayerGroup.addTo(this.map)
  // Ajustez la taille des cercles lorsque le niveau de zoom change
    this.map.on('zoomend', () => {
      this.adjustCircleSizes();
    });
  }

  onEachFeature(feature, layer) {
    let popupContent = `
      <a href="#" class="text-decoration-none" data-controller="spot-forecast" data-action="click->spot-forecast#open" data-spot-forecast-url-value="/spots/${feature.properties.slug}/details">        <div class="d-flex flex-column align-items-center justify-content-center p-0">
          <div class="text-center">${feature.properties.name} // ${feature.properties.sport}</div>
          <button type="button" class="btn btn-outline-success btn-sm mt-2 px-3 rounded-pill">Forecast</button>
        </div>
      </a>`;

    layer.bindPopup(popupContent);
  }


  convertSpot(List) {
    let data = [];

    List.forEach((spotParam)=> {
      let spot = {
        "type": "Feature",
        "properties": {
          "slug": spotParam['slug'],
          "name": spotParam['label'],
          "sport": spotParam['sport']
        },
        "geometry": {
          "type": "Point",
          "coordinates": [spotParam['longitude'],spotParam['latitude']]
        }
      };

      data.push(spot);
    });

    return data;
  }

 handleLocationFound(e) {
      this.updateGeoCookie(e.latlng);
      this.updateSessionStorage(e.latlng);
      this.fetch_session(e.latlng.lat, e.latlng.lng, this.pageValue);
  }

  // Fonction pour calculer le rayon en fonction du niveau de zoom
calculateRadius(zoomLevel) {
  switch (
    true // Notez que nous testons `true` pour permettre l'évaluation d'expressions
  ) {
    case zoomLevel <= 5:
      return 8000;
    case zoomLevel <= 8:
      return 2500;
    case zoomLevel <= 11:
      return 350;
    case zoomLevel <= 12:
      return 180;
    case zoomLevel <= 13:
      return 150;
    case zoomLevel <= 14:
      return 100;
    case zoomLevel <= 15:
      return 75;
    case zoomLevel <= 17:
      return 30;
    default:
      return 15;
  }
}


// Fonction pour ajuster la taille des cercles en fonction du zoom actuel
adjustCircleSizes() {
  const newRadius = this.calculateRadius(this.map.getZoom());
  this.circleLayerGroup.eachLayer((layer) => {
    if (layer instanceof L.Circle) {
      layer.setRadius(newRadius);
    }
  });
};
}
