import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("wheel", this.handleWheel.bind(this), {
      passive: false,
    });
  }

  disconnect() {
    this.element.removeEventListener("wheel", this.handleWheel.bind(this));
  }

  handleWheel(event) {
    // Empêche le scroll vertical de la page
    event.preventDefault();

    // Utilise deltaY (scroll vertical) pour faire défiler horizontalement
    const scrollAmount = event.deltaY * 0.5;
    // Défilement horizontal plus fluide
    this.element.scrollLeft += scrollAmount;

    // Alternative avec un comportement plus doux
    // this.element.scrollBy({
    //   left: scrollAmount,
    //   behavior: 'smooth'
    // })
  }
}
