import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    model: String,
  };

  connect() {
  }

  update(event) {
    const clickedElement = event.currentTarget;
    const url = clickedElement.dataset.updateModelForecastUrlValue;
    const model = clickedElement.dataset.updateModelForecastModelValue;
    this.fetchUpdatedForecast(url, model);
  }

  fetchUpdatedForecast(url, model) {
    fetch(`${url}?model=${model}`, {
      method: "GET",
      headers: {
        Accept: "text/html",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        const newInfoTable = doc.getElementById("info-table");
        const newTable = doc.getElementById("table");

        if (newInfoTable) {
          document.getElementById("info-table").innerHTML =
            newInfoTable.innerHTML;
        }

        if (newTable) {
          document.getElementById("table").innerHTML = newTable.innerHTML;
        }
      })
      .catch((error) => console.error("Error:", error));
  }
}
