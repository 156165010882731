import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    dismissAfter: Number,
  };

  connect() {
    this.dismissTimeout = setTimeout(() => {
      this.dismiss();
    }, this.dismissAfterValue || 23000);
  }

  disconnect() {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
    }
  }

  dismiss() {
    this.element.remove();
  }
}
