import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dialog"];

   connect() {
    // Empêcher la propagation des clics sur la modale
    this.dialogTarget.addEventListener('click', (event) => {
      event.stopPropagation();
    });

    // Gérer la fermeture avec Escape
    // document.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  disconnect() {
    // Nettoyage des event listeners lors de la déconnexion
    // document.removeEventListener('keydown', this.handleKeydown.bind(this));
  }

  loadContent(event) {
    event.preventDefault();
    event.stopPropagation();
    const url = event.currentTarget.dataset.url;
    const dialog = this.dialogTarget;

    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
          "X-Requested-With": "XMLHttpRequest"
      },
      credentials: "same-origin",
    })
      .then((response) => response.text())
      .then((html) => {
        dialog.innerHTML = html;
        dialog.showModal(); // Ouvre le <dialog>
      })
      .catch((error) => console.log(error));
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dialogTarget.close();
  }
}
