window.getCookie = function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

window.getValFromCookie = function getValFromCookie(cookie) {
  let temp = decodeURI(cookie)
  let latitude = parseFloat(temp.split('|')[0]);
  let longitude = parseFloat(temp.split('|')[1]);
  return [latitude, longitude]
}

window.eraseCookie = function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

window.setCookie = function setCookie(lat,lon) {
    var cookie_val = lat+ "|" + lon;
    document.cookie = "lat_lng=" + escape(cookie_val) + "; path=/; SameSite=None; Secure";
}

