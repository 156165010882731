import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollSpeed = 1.5; // Facteur de vitesse de défilement (ajustable)
    this.element.addEventListener("wheel", this.handleScroll.bind(this), {
      passive: false,
    });
  }

  disconnect() {
    this.element.removeEventListener("wheel", this.handleScroll.bind(this));
  }

  handleScroll(event) {
    if (event.deltaY !== 0) {
      event.preventDefault();

      // Si la touche Shift est pressée, laisse le comportement par défaut (scroll vertical)
      if (event.shiftKey) return;

      // Calcule la distance de défilement
      const scrollAmount = event.deltaY * this.scrollSpeed;

      // Applique le défilement horizontal
      this.element.scrollLeft += scrollAmount;

      // Vérifie les limites de défilement pour l'inertie
      if (
        this.element.scrollLeft <= 0 ||
        this.element.scrollLeft >=
          this.element.scrollWidth - this.element.clientWidth
      ) {
        return;
      }
    }
  }
}
