// app/javascript/controllers/auto_modal_controller.js
import SpotForecastController from "./spot_forecast_controller";

export default class extends SpotForecastController {
  connect() {
    // Appelle d'abord le connect du parent pour la création de la modale
    super.connect();

    // Vérifie si un paramètre spot est présent dans l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const spotSlug = urlParams.get("spot");

    if (spotSlug) {
      // Construire l'URL pour le spot
      const spotUrl = `/spots/${spotSlug}/details`;

      // Nettoyer l'URL sans recharger la page
      window.history.replaceState({}, "", "/");

      // Utiliser directement la méthode du parent
      this.fetchAndDisplayModal(spotUrl);
    }
  }
}
