// controllers/onboarding_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["moveTheMap","close-button"];

  connect() {
    this.map = window.map;
    this.map.on("dragend", this.hidePartial.bind(this));

    if (
      window.innerWidth > 576 &&
      sessionStorage.getItem("partialHidden") !== "true"
    ) {
      this.showPartial();
    }
    // Add event listener to 'close-button'
    const closeButton = document.getElementById("close-button");
    if (closeButton && sessionStorage.getItem("partialHidden") !== "true") {
      closeButton.addEventListener("click", this.showPartial.bind(this));
    }
  }



  hidePartial() {
    this.moveTheMapTarget.style.display = "none";
    sessionStorage.setItem("partialHidden", "true");
  }

  showPartial() {
    this.moveTheMapTarget.style.display = "flex"; // Or 'block', depending on your layout
  }
}
