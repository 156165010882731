import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    latitude: Number,
    longitude: Number,
  };

  open() {
    const url = `https://www.google.com/maps/search/?api=1&query=${this.latitudeValue},${this.longitudeValue}`;
    window.open(url, "_system");
  }
}
