import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["geocodeInput", "results", "userInfo", "logo"];

  connect() {
    this.search_bar_geocoder = L.Control.Geocoder.nominatim();
    this.timeoutId = null;
    // this.checkScreenSize();
    // window.onload = this.checkScreenSize.bind(this);
    // window.onresize = this.checkScreenSize.bind(this);
    document.addEventListener("mousedown", this.handleMouseDownBound);
  }

  disconnect() {
        document.removeEventListener("mousedown", this.handleMouseDownBound);
  }

 checkScreenSize() {
    if (window.innerWidth <= 699) {
      // garder le type de l'input en "text" et modifier seulement le placeholder
      this.geocodeInputTarget.placeholder = "";
      this.geocodeInputTarget.onfocus = function () {
        this.placeholder = "  Search a city...";
      };
    } else {
      this.geocodeInputTarget.placeholder = " Search a city...";
      this.geocodeInputTarget.onfocus = null;
    }
}

  handleFocus() {
    if (window.innerWidth <= 699) {
      this.userInfoTarget.style.display = "none";
      this.logoTarget.style.display = "none";
    }
  }

  handleBlur() {
    if (window.innerWidth <= 699) {
      this.userInfoTarget.style.display = "block";
      this.logoTarget.style.display = "block";
    }
  }


  handleKeyUp(event) {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.search_bar_geocoder.geocode(event.target.value, (results) => {
        this.resultsTarget.innerHTML = "";
        if (results.length > 0) {
          this.resultsTarget.style.display = "block";
        } else {
          this.resultsTarget.style.display = "none";
        }
        results.forEach((result) => {
          let resultLi = document.createElement("li");
          resultLi.textContent = result.name;
          resultLi.classList.add("leaflet-control-geocoder-result");

          let handleClickOrTouch = () => {
            map.setView([result.center.lat, result.center.lng], 13);
            this.resultsTarget.style.display = "none";
            this.geocodeInputTarget.value = "";
            this.updateGeoCookie(result.center);
            this.updateSessionStorage(result.center);

            // Trigger the custom event
            var event = new CustomEvent("searchLocationChange", {
              detail: { lat: result.center.lat, lng: result.center.lng },
            });
            document.dispatchEvent(event);
          };

          resultLi.addEventListener("click", handleClickOrTouch);
          resultLi.addEventListener("touchend", handleClickOrTouch);


          this.resultsTarget.appendChild(resultLi);
        });
      });
    }, 500);
  }

  handleMouseDownBound = event => {
    this.handleMouseDown(event);
  }

  handleMouseDown(event) {
  if (
    event.target !== this.geocodeInputTarget &&
    event.target.parentNode !== this.resultsTarget
  ) {
    this.resultsTarget.style.display = "none";
  }
}

 updateGeoCookie(center) {
    var cookie_val = center.lat + "|" + center.lng;
    document.cookie =
      "lat_lng=" + encodeURIComponent(cookie_val) + "; path=/; SameSite=None; Secure";
  }

  updateSessionStorage(center) {
    localStorage.setItem("last_latitude", center.lat);
    localStorage.setItem("last_longitude", center.lng);
  }


}
