import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "map" ]
  static values = {
    slug: String,
  };

  connect() {
  }

  copy() {
    const url = `${window.location.origin}/spots/${this.slugValue}`;
    navigator.clipboard.writeText(url).then(() => {
      alert("Link copied to clipboard");
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  position() {
    let position = window.map.getCenter();
    let sportsCookie = document.cookie.split(';').find(row => row.trim().startsWith('sports='));
    let sports = sportsCookie ? sportsCookie.split('=')[1] : undefined;

    let baseUrl = "https://www.spots.guru/?latitude=" + position.lat + "&longitude=" + position.lng;
    let url = sports ? baseUrl + "&sport=" + sports : baseUrl;

    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard");
  }
}

