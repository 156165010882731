import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];

  connect() {
    this.duplicateContent();
    this.toggleDisplay();
    window.addEventListener("resize", () => this.toggleDisplay());
  }

  disconnect() {
    window.removeEventListener("resize", () => this.toggleDisplay());
  }

  duplicateContent() {
    const displayElement = document.getElementById("display_service");
    if (this.hasSourceTarget && displayElement) {
      displayElement.innerHTML = this.sourceTarget.innerHTML;
      displayElement.classList.add("service");
    }
  }

  toggleDisplay() {
    const width = window.innerWidth;
    const displayElement = document.getElementById("display_service");
    const sourceElement = this.sourceTarget;
    if (width <= 980) {
      displayElement.style.display = "none";
      sourceElement.style.display = "block";
    } else {
      displayElement.style.display = "block";
      sourceElement.style.display = "none";
    }
  }
}
